

*, html, body {
  box-sizing: border-box !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsl(0 0% 98%) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}

.formControl {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  line-height: 26px;
  position: relative;
  padding: 4px 10px;
  border-radius: 3px;
  margin-top: 4px;
  margin-bottom: 8px;
  box-shadow: rgb(15 15 15 / 5%) 0 1px inset;
  background: rgba(242,241,238,0.6);
  border: 1px solid rgba(15,15,15, 0.15);
}
.label {
  display: block;
  margin-bottom: 4px !important;
  font-size: 14px !important;
  color: rgba(55,53,47,0.65) !important;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}
.selection {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
}
.selection-image {
  width: 180px !important;
  height: 180px !important;
}
.selection-text {
  font-size: large !important;
  font-weight: 600 !important;
}
.selection-progress {
  position: absolute;
}

.child {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px !important;
  border-width: 2px !important;
  width: 150px !important;
}
.child-image {
  width: 80px !important;
  height: 100px !important;
  object-fit: contain !important;
  object-position: center !important;
}
.child-text {
  font-size: large !important;
  font-weight: 600 !important;
}

.container {
  position: relative;
  /* The container determains the size. */
  width: 100svw;
  height: 100svh;
}

.container > .loading-overlay {
  /* We'll render the overlay on top of the Unity Application. */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: grey;
  /* We'll set the following Flex properties in order to center the text. */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > .unity {
  /* The Unity Application matches it size to the container. */
  width: 100%;
  height: 100%;
}

.game-header {
  position: fixed;
  bottom:0;
  width: 100%;
  padding: 20px;
}

.nf-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100lvh;
}
.nf-title {
  margin: 0;
  padding: 0;
  font-size: 5rem;
  font-weight: 700;
}
.nf-subtitle {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
}